import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    tableHeading: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '1.1rem',
        color: COLORS.tableData,
    },
    rowStyles: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F7F4F4',
        }
    },
    dataStyles: {
        fontWeight: 'bold',
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        color: COLORS.tableData,
    },
    hideSpacer: {
        display: 'none'
    },
    pickupButton: {
        textTransform: 'none',
        backgroundColor: COLORS.buttonsColor,
        '&:hover': {
            backgroundColor: COLORS.buttonsColor,
        }
    },
    searchButton: {
        margin: '0.5rem 0rem 1rem 0rem'
    },
    searchField: {
        '& .MuiOutlinedInput-root': {
            height: '2.688rem',
            marginTop: '0.5rem'
        },
    },
    toolbarPagination: {
        padding: 0,
    },
    ordID: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none'
    },
    rowsCount: {
        fontSize: '0.7rem'
    },
    searchFieldMobile: {
        '& .MuiOutlinedInput-root': {
            height: '1.688rem',
            marginTop: '0.5rem'
        },
    },
    pickupButtonMobile: {
        textTransform: 'none',
        backgroundColor: COLORS.buttonsColor,
        '&:hover': {
            backgroundColor: COLORS.buttonsColor,
        },
        height: '1.688rem',
    },
    searchButtonMobile: {
        margin: '0.5rem 0rem 1rem 1rem'
    },
    tableHeadingMobile: {
        fontWeight: 'bold',
        fontSize: '0.6rem',
        lineHeight: '0.7rem',
        color: COLORS.tableData,
    },
    dataStylesMobile: {
        fontWeight: 'bold',
        fontSize: '0.5rem',
        lineHeight: '0.7rem',
        color: COLORS.tableData,
    },
    tableCellMobile: {
        padding: 8
    },
    pickupButtonsMobile: {
        textTransform: 'none',
        backgroundColor: COLORS.buttonsColor,
        '&:hover': {
            backgroundColor: COLORS.buttonsColor,
        },
        fontSize: '0.6rem'
    },
    tableCellTablet:{
        padding: 4
    },
    toolbarPaginationMobile: {
        padding: 0,
        justifyContent: 'center'
    },
    tableHeaderSearch: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        }
    },
    tableHeaderPagination: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start'
        }
    }
}));

export { useStyles };
