import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
    tab: {
        height: '4.25rem',
        border: '1px solid #C9CECF !important',
        boxSizing: 'border-box',
        margin: '0rem 0.125rem',
        fontWeight: 'bold',
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        textTransform: 'unset',
        [theme.breakpoints.up('sm')]: {
            flex: 1
        }
    },
    selected: {
        display: `none`,
    },
    customLabelHeading: {
        fontWeight: 'bold',
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
    },
    headingWrapper: {
        display: 'inline',
    },
    firstTab: {
        textTransform: 'uppercase'
    },
    selectedWrapper: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: '#FFFFFF'
    },
    customLabelText: {
        fontWeight: 'normal'
    },
    tabsWrapper: {
        marginBottom: '1rem'
    },
    customLabelHeadingMobile: {
        fontWeight: 'bold',
        fontSize: '0.5rem',
    },
    tabMobile: {
        height: '1rem',
        border: '1px solid #C9CECF !important',
        boxSizing: 'border-box',
        margin: '0rem 0.06rem',
        fontWeight: 'bold',
        fontSize: '0.6rem',
        lineHeight: '0.8rem',
        textTransform: 'unset',
        width: '100%',
        maxWidth: '100%'
    },
    panelStylesMobile: {
        width: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: COLORS.buttonsColor
    }
}));

export { useStyles };
