import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import apolloClient from './graphql/Client';
import { Dashboard, Callback, OrderView, Public } from './containers';
import { PrivateRoute } from './routes';
import { MainLayout } from './layouts'
import { ErrorBoundary } from './utils';
import { AuthContext } from "./context";
import { FeedbackProvider } from "./providers";
import { UnAuthorized } from "./components/Unauthorized/UnAuthorized";

const App = () => {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [response, setResponse] = useState();
  const [responseHistorical, setResponseHistorical] = useState();

  // Restore tokens onload
  useEffect(() => {
    const tokens = localStorage.getItem('tokens');
    const refreshCount = localStorage.getItem('count');
    if(refreshCount == null){
      localStorage.setItem('count', 0)
    }
    if (tokens) {
      setAuthTokens(JSON.parse(tokens));
    }
    setIsLoading(false);
    setCurrentTab(0)
  }, []);

  const setAuthTokens = (tokens) => {
    localStorage.setItem('tokens', JSON.stringify(tokens));
    setToken(tokens);
  }

  const getResponse = () => {
    if (currentTab===5) {
      return responseHistorical || { fulfillmentOrdersHistorical: []}
    } else {
      return response || { fulfillmentOrders: [] }
    }
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AuthContext.Provider value={{ 
          isAuthenticated: !!token, 
          setAuthTokens: setAuthTokens,
          authTokens: token, 
          isLoading: isLoading,
        }}
      >
        <Router>
          <Switch>
            <PrivateRoute exact path="/">
                <ErrorBoundary>
                  <FeedbackProvider>
                    <MainLayout>
                        <Dashboard response={getResponse()} setResponse={setResponse} setResponseHistorical={setResponseHistorical} currentTab={currentTab} setCurrentTab={setCurrentTab} searchValue={searchValue} setSearchValue={setSearchValue}  />
                    </MainLayout>
                  </FeedbackProvider>
                </ErrorBoundary>
            </PrivateRoute>
            <Route exact path="/callback">
              <ErrorBoundary>
                  <Callback />
              </ErrorBoundary>
            </Route>
            <Route exact path="/proxy">
              <ErrorBoundary>
                <MainLayout>
                  <Public />
                </MainLayout>
              </ErrorBoundary>
            </Route>
            <Router path="/unauthorized">
              <ErrorBoundary>
                <MainLayout>
                  <UnAuthorized />
                </MainLayout>
              </ErrorBoundary>
            </Router>
            <PrivateRoute exact path="/order/:ordId?">
              <FeedbackProvider>
                <ErrorBoundary>
                  <MainLayout>
                    <OrderView />
                  </MainLayout>
                </ErrorBoundary>
              </FeedbackProvider>
            </PrivateRoute>
          </Switch>
        </Router>
      </AuthContext.Provider>
    </ApolloProvider>
  );
};

export default App;
