import { useCallback, useContext } from 'react';
import { FeedbackContext } from '../context';
import { CLOSE_FEEDBACK, SET_FEEDBACK } from '../actions';

export const useFeedback = () => {
  const { context } = FeedbackContext;
  const [state, dispatch] = useContext(context);

  const setFeedback = useCallback((payload) => {
    dispatch({
      type: SET_FEEDBACK,
      payload
    });
  }, []);

  const closeFeedback = useCallback(() => {
    dispatch({
      type: CLOSE_FEEDBACK
    });
  }, []);

  return {
    setFeedback,
    closeFeedback,
    state
  };
};
