import React from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import styles from "./styles";
import  LocalOfferIcon  from '@material-ui/icons/LocalOffer';

class CustomerReceipt extends React.Component {
    constructor(props){
      super(props);
    }


    render() {
      const percentage = (totalValue, partialValue) =>{
        return Math.round((partialValue / totalValue )*100)
     } 
      const { classes, orderData, userInfo } = this.props;
      const productSubTotal = orderData.FulfillmentOrderLineItem.reduce(
        (acc, orderLineItem) => {
          return acc + orderLineItem.Quantity * orderLineItem.UnitPrice +  orderLineItem.OrderItemSummary.TotalLineAdjustmentAmtWithTax;
        },
        0
      );
  
      const orderNotes = orderData.FulfillmentOrderLineItem.length
        ? orderData.FulfillmentOrderLineItem[0]?.OrderItemSummary
            ?.OrderDeliveryGroupSummary?.notes__c
        : null;
      return(
      <div className={classes.printPage}>
        <div className={classes.heading}>
          Mud Bay {userInfo && userInfo.storeName}<br /> {orderData.FulfilledFromLocation} <br /> {orderData.WarehouseToPhone}
          <br /> 
        </div>

        <div  className={classes.orderText}>
          <div style={{ fontWeight: "bold" }}>
            <div className="order-text">Order #{orderData.OrderSummary.OrderNumber}</div>
          </div>
          <div style={{ fontWeight: "bold" }}>
            <div className="order-text">{moment(orderData.CreatedDate).format("D/MM/YYYY")}</div>
          </div>
        </div>

        <div className={classes.orderText}>
          <div>
            <div >{orderData.FulfilledToName}</div>
          </div>
          <div>
            <div >{orderData.FulfilledToPhone}</div>
          </div>
        </div>
        <div>
          <div className={classes.topTitle}>{orderData.Type} Order</div>
        </div>
        <div>
        <Divider className={classes.orderDivider} />
          {orderData.FulfillmentOrderLineItem.map((row, index) => {
            return (
              <tr key={index}>
                <tr>
                  <b>{row.Description}</b>
                </tr>
                <tr>
                  {row.OrderItemSummary.ProductCode}
                </tr>

                <div style={{ display:"flex",justifyContent:"space-between" }} >
                  <div style={{ fontWeight:"bold" }} >Quantity</div>

              <div>{row.Quantity}
                 <b> x </b>
                  ${row.UnitPrice}</div>
                  <div>
                  ${parseFloat(row.Quantity * row.UnitPrice).toFixed(2)}
                  </div>
                </div>
                {(row.OrderItemSummary.TotalLineAdjustmentAmtWithTax) < 0 &&
                <div className={classes.spanTagWrapper}>
                <div style={{ display:"flex", justifyContent:"flex-end"}} >
                  <span className={classes.spanTag1}>
                  <LocalOfferIcon className={classes.orderTag}   />
                  {`( ${percentage(parseFloat(
                        row.Quantity * row.UnitPrice
                       ), parseFloat(row.OrderItemSummary.TotalLineAdjustmentAmtWithTax)).toFixed(2)}% off)`}
                  </span>

                  <span className={classes.spanTag1}  style={{marginLeft:"12px"}} >
                  {`-$ ${(Math.abs(row.OrderItemSummary.TotalLineAdjustmentAmtWithTax)).toFixed(2)}`}
                  </span>
                  </div>
                </div>
                }
                  <div className={classes.spanTag2}>
                     Line Total<div style={{ marginRight:"2px", marginLeft:"18px"}}>$</div>
                      {parseFloat(
                        row.Quantity * row.UnitPrice + row.OrderItemSummary.TotalLineAdjustmentAmtWithTax
                      ).toFixed(2)}
                  </div>
              </tr>
            );
          })}
        </div>
        {orderNotes && (
          <div className={classes.orderClientData}>
            Notes: {`${orderNotes}`}
          </div>
        )}

        <div>
          <Divider className={classes.orderDivider} />
          <table className={classes.orderSummary}>
            <tbody>
              <div className={classes.alignRight}>  <div className={classes.itemText}>Items Total: ${parseFloat(productSubTotal).toFixed(2)}</div>  </div>
              {(orderData.OrderSummary.TotalAdjDistAmountWithTax) < 0 &&
              <>
              <div className={classes.alignRight}>  <div className={classes.itemText}> Order Discount: {`-$${(Math.abs(orderData.OrderSummary.TotalAdjDistAmountWithTax)).toFixed(2)}`}</div></div>
              <div className={classes.alignRight}>  <div className={classes.itemText}>Discounted Items Total: ${(productSubTotal + orderData.OrderSummary.TotalAdjDistAmountWithTax).toFixed(2)}</div></div>
              </>
              }
              <hr style={{marginLeft:"62px", borderTop: "2px dashed", alignItems: "right" }} />
              {(orderData.OrderSummary.TotalAdjustedDeliveryAmount) > 0 &&
              <div style={{ display:"flex", justifyContent:"flex-end", fontWeight:"bold"}} >
              <span>Shipping Total:</span>
                  <span>${orderData.OrderSummary.TotalAdjustedDeliveryAmount.toFixed(2)}</span>
              </div>
              }
              <div style={{ display:"flex", justifyContent:"flex-end", fontWeight:"bold"}} >
              <span>Tax Total:</span>
                  <span>
                    ${orderData.TotalTaxAmount.toFixed(2)}
                  </span>
              </div>
          
              <hr style={{ marginLeft:"62px", borderTop: "2px dashed", alignItems: "right" }} />
              <div style={{ display:"flex", justifyContent:"flex-end", fontWeight:"bold"}} >
              <span>Order Total:</span>
                  <span>
                  ${(productSubTotal + orderData.OrderSummary.TotalAdjDistAmountWithTax + orderData.OrderSummary.TotalAdjustedDeliveryAmount 
                    + orderData.TotalTaxAmount).toFixed(2)}
                  </span>
              </div>
            </tbody>
          </table>
        </div>
      </div>
    );
    }
}

export default withStyles(styles)(CustomerReceipt);
