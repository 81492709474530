import React from 'react'
import endpoint from '../../config/endpoint.config';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';


const ShowAlert = () => {
const classes = useStyles();

const onLogout = () => {
    window.location.href = endpoint.COGNITO_LOGOUT;
    localStorage.clear();
}
  return (
    <div className={classes.modalDialogWrapper}>
     <div className={classes.modalDialog} >
        <div>
            <img alt="logo" className={classes.modalDialogImg} src="https://d1lcia0inyjsq.cloudfront.net/us-east-2_xlikHWfq2/ALL/20210702212640/assets/images/image.jpg" />
        </div>
        <h1>Unable to retrieve orders, please contact support</h1>
        <Button onClick={onLogout} className={classes. modalDialogButton}  href={endpoint.COGNITO_LOGOUT}>Logout</Button>
        </div>
        <div>
        </div>
    </div>

  )
}

export default ShowAlert