const styles = () => ({
    printPage: {
        padding: '3mm',
        width: '103mm',
        boxShadow:"0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
    },
    orderNo: {
        width: '100%',
        textAlign: 'right',
        fontSize: '16px',
        display: 'flex',
        fontWeight:"bold",
        justifyContent: 'space-between',
    },
    orderType: {
        width: '100%',
        fontSize: '16px',
        display: 'flex',
        fontWeight:"bold",
        justifyContent: 'center',
        paddingBottom: '3mm'
    },
    orderDate:{
        width: '100%',
        fontSize: '16px',
        display: 'flex',
        fontWeight:"bold",
        justifyContent: 'space-between',
        paddingBottom: '5mm'
    },
    orderClientData: {
        width: '100%',
        fontSize: '16px',
        display: 'flex',
        fontWeight:"bold",
        justifyContent: 'center',
        textAlign:"center",
        marginLeft:"auto",
        marginRight:"auto",
    },
    orderClientData1: {
        width: '100%',
        fontSize: '16px',
        display: 'flex',
        fontWeight:"bold",
        justifyContent: 'center',
        paddingBottom: '2mm'
    },
    orderClientDataNotes:{
        width: '100%',
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'left',
        textDecorationLine: 'underline',
        textUnderlineOffset: '3px',
        paddingBottom:'1mm'
    },
    orderClientDataName: {
        width: '100%',
        textAlign: 'right',
        fontSize: '16px',
        display: 'flex',
        fontWeight:"bold",
        justifyContent: 'space-between',
    },
    orderTable: {
        width: '100%',
        fontSize: '8pt',
        textAlign: 'left'
    },
    orderDivider: {
        borderBottom:"1px solid",
        marginBottom :"12px",
        marginTop :"12px",
    },
    orderSummary: {
        width: '100%',
        fontSize: '12pt'
    },
    frozenItems: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '16px',
        fontWeight:"400",
        paddingBottom:'3mm',
    },
    orderPickedBy: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '16px',
        fontWeight:"400",
        marginTop:'6mm',
        paddingLeft:'3mm'
    },
    pictureBox: {
        border:"2px solid black",
        height:"60px"
    },
    category:{
        fontSize: '16px',
        wordBreak: 'keep-all',
        fontStyle: 'italic',
        fontWeight: '400',
        textDecorationLine: 'underline'
    },
    orderNotes:{
        fontSize: '16px',
        fontWeight: '400',
    }
});

export default styles;