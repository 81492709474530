import React, {useCallback, useEffect} from 'react';
import {
    Grid,
    Tabs,
    Tab,
    Typography,
    Box
} from '@material-ui/core';
import { DataTable  } from '../../components';
import { useStyles } from './styles';
import { mapRowsData } from '../../utils/DataTableUtils';

export const TabsTable = (props) => {
    const classes = useStyles();
    const { data, tabs, currentTab, setCurrentTab, searchValue, setSearchValue,refreshPage } = props;
    const handleChange = (event, newValue ) => {
        window.sessionStorage.setItem('activeTab', newValue);
        setCurrentTab(newValue);
        setSearchValue('')
    };

    useEffect(()=>{
        window.sessionStorage.setItem('activeTab', currentTab); 
    },[])

    const TabPanel = useCallback((props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography component="span">{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }, [])

    const getDataRows = useCallback((filterCriteria) => {
        const dataLoaded = data.fulfillmentOrders || data.fulfillmentOrdersHistorical
        if (data && dataLoaded) {
            if (filterCriteria.filterStatus !== 'all' && filterCriteria.filterType !== 'all') {
                return mapRowsData(dataLoaded.filter((item) => item.Type === filterCriteria.filterType && item.Status === filterCriteria.filterStatus))
            }
            return mapRowsData(dataLoaded);
        }
        return []
    }, [data])

    const TableTabs = useCallback(()=> {
        return (
            <Grid item className={classes.panelStylesMobile}>
                <Tabs
                    variant="scrollable"
                    value={currentTab}
                    onChange={handleChange}
                    TabIndicatorProps={{ className: classes.selected }}
                    className={classes.tabsWrapper}
                    scrollButtons="auto"
                >
                    {tabs.map(((tab, index) => {
                        return (
                            <Tab
                                key={index}
                                label={
                                    <>
                                        <span className={classes.customLabelHeading}>{tab.title}</span>
                                        <span className={classes.customLabelText}>{tab.description}</span>
                                        {tab.filterType !== 'all' && (
                                            <>
                                                <br />
                                                <span className={classes.customLabelHeading}>{getDataRows({filterType: tab.filterType, filterStatus: tab.filterStatus}).length} </span>
                                                <span className={classes.customLabelText}> Orders</span>
                                            </>
                                        )}
                                    </>
                                }
                                className={classes.tab}
                                classes={{
                                    wrapper: classes.headingWrapper,
                                    selected: classes.selectedWrapper
                                }}
                            />
                        )
                    }))}
                </Tabs>
            </Grid>
        )
    }, [data, currentTab])
    return (
        <Grid container direction={"row"}>
            <TableTabs />
            <Grid item className={classes.panelStylesMobile}>
                {tabs.map((row, index) => (
                    <TabPanel value={currentTab} index={index} key={index}>
                        <DataTable doSearch={props.doSearch} refreshPage={ refreshPage} rows={ getDataRows({filterType: row.filterType, filterStatus: row.filterStatus}) } data={row} searchValue={searchValue} setSearchValue={setSearchValue} currentTab={currentTab} />
                    </TabPanel>
                ))}
            </Grid>
        </Grid>
    );
}
