import React, {useRef, useEffect, useCallback, useState, useMemo} from 'react';
import { useReactToPrint } from 'react-to-print';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Grid, Divider, Button, Link } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { OrderStatus, OrderCustomerDeliveryInfo, OrderDetailsDataTable, Loader } from '../../components';
import printIcon from '../../assets/img/print.svg';
import { GET_ORDER_VIEW, CANCEL_ORDER, PROCESS_ORDER, GET_CANCEL_REASONS } from './query';
import { useStyles } from './styles';
import { useFeedback } from "../../hooks";
import TicketPrint from "../../components/TicketPrint/TicketPrint";
import CustomerReceipt from "../../components/CustomerReceipt/CustomerReceipt";
import { useUser } from "../../hooks";



export const OrderView = () => {
    const classes = useStyles()
    const history = useHistory();
    const componentRef = useRef();
    const componentReceiptRef = useRef();
    const { user: { userInfo } } = useUser();
    const ordId = history.location.pathname.split('/')[2]
    const { setFeedback } = useFeedback();
    const [quantities, setQuantities] = useState([])

    const { loading, error, data } = useQuery(GET_ORDER_VIEW, {
        variables: { id: ordId }
    });

    const { loading: loadingReasons, error: errorReasons, data: reasonsData } = useQuery(GET_CANCEL_REASONS);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleReceiptPrint = useReactToPrint({
        content: () => componentReceiptRef.current,
    });
    
    if (!ordId) {
        history.push('/')
    }

    useEffect(() => {
        if (error || errorReasons) {
            setFeedback({
                display: true,
                message: error || errorReasons,
                type: 'error'
            });
        }
    }, [error, errorReasons]);

    const changeQty = useCallback((val, index) => {
        const newValues = quantities.map((item, i) => i === index ? val : item);
        setQuantities(newValues);

        data.fulfillmentOrder.FulfillmentOrderLineItem = data && data.fulfillmentOrder.FulfillmentOrderLineItem.map((item, i) => {
            return index === i ? {...item, TotalLineAmount: (item.UnitPrice * val), TotalPicked: val} : item;
        })

    }, [data, quantities]);

    const totalItemsCount = useMemo(() => {
        return  quantities && quantities.reduce((a, b) => a + b, 0);
    }, [quantities]);


    useEffect(() => {
        setQuantities(() => data && data.fulfillmentOrder.FulfillmentOrderLineItem.map((row) => row.Quantity))
    }, [data])

    if (loading || loadingReasons) {
        return <Loader />
    }
   return (
       <Grid container direction="column">
           {(data && data.fulfillmentOrder) && (
               <>
                   <div style={{ display: 'none' }}>
                       <TicketPrint ref={componentRef} orderData={data.fulfillmentOrder} />
                       <CustomerReceipt userInfo={userInfo} ref={componentReceiptRef} orderData={data.fulfillmentOrder} />
                   </div>
                   <Grid item xs={12}>
                       <Button color="primary" className={classes.backButton}>
                           <Link component={RouterLink} to='/'>&#60; Back to Orders</Link>
                       </Button>
                   </Grid>
                   <OrderStatus fulfillmentOrder={data.fulfillmentOrder}  />
                   <Divider />
                   <OrderCustomerDeliveryInfo
                       fulfillmentOrder={data && data.fulfillmentOrder}
                       quantities={quantities}
                       totalItemsCount={totalItemsCount}
                   />
                   <Divider />
                   <Grid item container direction="row" justify="space-between" className={classes.root}>
                       <Grid item className={classes.orderDetailsLabel}>
                           Order Details
                       </Grid>
                       <Grid item>
                           <Button color="primary" className={classes.printTicketLabel} onClick={handlePrint}>
                               <img src={printIcon} alt="print-icon" />Print Pick Ticket
                           </Button>
                       </Grid>
                   </Grid>
                   <Divider />   
                   <Grid item xs={12}>
                       <OrderDetailsDataTable
                           changeQty={changeQty}
                           quantities={quantities}
                           totalItemsCount={totalItemsCount}
                           rows={data.fulfillmentOrder}
                           cancelOrderQuery={CANCEL_ORDER}
                           processOrderQuery={PROCESS_ORDER}
                           ordId={data.fulfillmentOrder.Id}
                           status={data.fulfillmentOrder.Status}
                           taxAmount={data.fulfillmentOrder.TotalTaxAmount}
                           reasons={reasonsData.cancelationReasons}
                           handleReceiptPrint={handleReceiptPrint}
                       />
                   </Grid>             
               </>  
           )}
       </Grid>
   )
}
