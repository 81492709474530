const apolloResolver = {
    setPalletteColor: (_, {color}, {cache}) => {
        const newTextColor = { value: color, __typename: 'PalletteColor' };
        const data = { palletteColor: newTextColor };
        cache.writeData({data});
        return newTextColor;
    }
};

export default apolloResolver;
