import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
export const UnAuthorized = () => {
    return (
     <Box align='center'>
         <h2>We are sorry...</h2>
         <Typography>The page you´re trying to access has restricted access.</Typography>
         <Typography>Please refer to your system administrator.</Typography>
     </Box>
    );
};

