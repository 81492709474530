import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '1rem 0 1rem 0',
    },
    backButton: {
        textDecoration: 'none',
        fontSize: '1rem',
        lineHeight: '1.188rem',
        letterSpacing: '0.04rem',
        textTransform: 'unset'
    },
    printTicketLabel: {
        fontWeight: 'bold',
        lineHeight: '1.188rem',
        fontSize: '1.188rem',
        color: theme.palette.primary.main,
        textTransform: 'none'
    },
    orderDetailsLabel: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        letterSpacing: '0.04rem'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: COLORS.buttonsColor
    }
}));

export { useStyles };
