/* eslint-disable */
import React, { useCallback, useState } from 'react';
import { Grid, Dialog, TextField, useMediaQuery, Button, MenuItem, InputAdornment, OutlinedInput, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useStyles } from './styles';


export const MaterialDialog = (props) => {
    const classes = useStyles();
    const { handleCloseDialoge, open, dialogType, finalizeOrder, cancelOrder, processOrder, ordId, reasons } = props;
    const [empPin, setEmpPin] = useState('');
    const [reason, setReason] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState({ pinError: '', reasonError: '' });
    const isMobile = useMediaQuery('(max-width:600px)');
    const isFinalizing = dialogType === 'final'

    const handleClose = () => {
        handleCloseDialoge();
    };
    const handleClickShowPassword = useCallback(() => {
        setShowPassword(!showPassword);
      }, [showPassword]);
    const handleProcessOrFinalizeOrder = useCallback(() => {
        if (!empPin) {
            setError({
                pinError: !empPin ? 'Pin is required!' : '',
                reasonError: !reason ? 'Reason is required!' : ''
            })
        } else if (empPin.length < 4 || empPin.length > 6) {
            setError({
                pinError: empPin.length < 4 || empPin.length > 6 ? 'Pin must be between 4 to 6 characters!' : '',
                reasonError: ''
            })
        }
        else {
            if (finalizeOrder)
                finalizeOrder(empPin, ordId);
            else if (processOrder)
                processOrder(empPin);
        }
    }, [empPin, processOrder])

    const handleCancelOrder = useCallback(() => {
        if (!empPin || !reason) {
            setError({
                pinError: !empPin ? 'Pin is required' : '',
                reasonError: !reason ? 'Reason is required' : ''
            })
        } else if (empPin.length < 4 || empPin.length > 6) {
            setError({
                pinError: empPin.length < 4 || empPin.length > 6 ? 'Pin must be between 4 to 6 characters!' : '',
                reasonError: ''
            })
        }
        else {
            cancelOrder(empPin, reason)
        }
    }, [empPin, reason, processOrder])

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={'sm'} fullWidth={true}>
            <Grid container direction="column" className={classes.root}>
                <Grid item className={classes.title}>
                    {isFinalizing ? 'Finalize Order' : dialogType === 'process' ? 'Pickup Order' : 'Are you sure your want to cancel this order?'}
                </Grid>
                {isFinalizing ?
                    <Grid item className={classes.question}>
                        Are you ready to finalize this order?
                    </Grid> :
                    dialogType === 'process' &&
                    <Grid item className={classes.question}>
                        Are you ready to pickup this order?
                        </Grid>
                }
                <form>
                    <Grid item className={classes.pin}>
                        <span className={classes.red}>* </span>Employee PIN
                    </Grid>
                    <Grid item lg={5} className={classes.pinField}>
                         <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={empPin}
                            onChange={(e) => setEmpPin(e.target.value)}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </Grid>
                    {!isFinalizing && dialogType !== 'process' &&
                        <>
                            <Grid item className={classes.pin}>
                                <span className={classes.red}>* </span>Reason for cancellation:
                            </Grid>
                            <Grid item lg={5} className={classes.pinField}>
                                <TextField
                                    className={classes.reasonSelect}
                                    select
                                    label='Select Reason'
                                    variant="outlined"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    error={error.reasonError.length !== 0}
                                    helperText={error.reasonError}
                                >
                                    {reasons && reasons.map((reason) => {
                                        return <MenuItem key={reason.value} value={reason.value}>{reason.label}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                        </>
                    }
                    <Grid container justify="flex-end">
                        <Grid item md={6} xs={12} className={classes.alignRight}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={isMobile ? `${classes.pickupButtonMobile} ${classes.cancelButton}` : `${classes.pickupButton} ${classes.cancelButton}`}
                                onClick={handleCloseDialoge}
                            >
                                {isFinalizing ? 'Do Not Finalize' : dialogType === 'process' ? 'Do Not Pickup Order' : 'Do Not Cancel Order'}
                            </Button>

                        </Grid>
                        <Grid item xs={12} md={4} lg={4} container justify="flex-end">
                            <Button
                                variant="contained" color="primary"
                                className={isMobile ? classes.pickupButtonMobile : classes.pickupButton}
                                onClick={isFinalizing || dialogType === 'process' ? handleProcessOrFinalizeOrder : handleCancelOrder}
                            >
                                {isFinalizing ? 'Finalize Order' : dialogType === 'process' ? 'Pickup Order' : 'Cancel This Order'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Dialog>
    );
}
