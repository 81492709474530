import { useEffect } from 'react';
import { useLazyQuery } from "react-apollo";
import { GET_USER_INFO } from "../graphql/queries/queries";
import { useAuth} from "./authHook";

export const useUser = () => {
    const [loadUserInfo, { loading, error, data }] = useLazyQuery(GET_USER_INFO);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated && !data) {
            loadUserInfo();
        }
    }, [data, isAuthenticated])

    return {
        user: {userInfo: data && data.userInfo},
        isLoading: loading,
        error
    };
};
