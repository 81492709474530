import React from 'react';
import {
    Grid,
    Backdrop,
    CircularProgress,
    useMediaQuery
} from '@material-ui/core';
import { useStyles } from './styles';

export const Loader = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();

    return (
        <Grid container direction={isMobile ? "row" : "column"}>
            <Backdrop className={classes.backdrop} open={true} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
}
