import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 750,
    },
    tableHeading: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '1.1rem',
        color: COLORS.tableData,
    },
    rowStyles: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F7F4F4',
        }
    },
    dataStyles: {
        fontWeight: 'bold',
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        color: COLORS.tableData,
    },
    ordID: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none'
    },
    tableHeadingMobile: {
        fontWeight: 'bold',
        fontSize: '0.6rem',
        lineHeight: '0.7rem',
        color: COLORS.tableData,
    },
    dataStylesMobile: {
        fontWeight: 'bold',
        fontSize: '0.5rem',
        lineHeight: '0.7rem',
        color: COLORS.tableData,
    },
    tableCellMobile: {
        padding: 8
    },
    printTicketLabel: {
        fontWeight: 'bold',
        lineHeight: '1.188rem',
        fontSize: '1.188rem',
        color: theme.palette.primary.main,
        textTransform: 'none'
    },
    tableCellTablet: {
        padding: 4
    },
    itemCount: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings,
        margin: '1.5rem 0 1rem 0'
    },
    infoHeading: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.016rem',
        color: COLORS.headings,
        marginBottom: '1rem'
    },
    infoContainer: {
        padding: '2.5rem 0rem 0.5rem 0rem !important'
    },
    infoText: {
        fontWeight: 300,
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings,
        marginBottom: '1rem'
    },
    pR: {
        paddingRight: '6rem'
    },
    pickupButton: {
        textTransform: 'none',
        backgroundColor: COLORS.buttonsColor,
        '&:hover': {
            backgroundColor: COLORS.buttonsColor,
        },
        marginTop: '2rem',
        marginBottom: '2rem',
        width: '50%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    pickupButtonMobile: {
        textTransform: 'none',
        backgroundColor: COLORS.buttonsColor,
        '&:hover': {
            backgroundColor: COLORS.buttonsColor,
        },
        marginTop: '2rem',
        width: '100%'
    },
    cancelButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'white',
        },
        border: `2px solid ${theme.palette.primary.main}`
    },
    alignRight: {
        textAlign: 'right'
    }
}));

export { useStyles };
