import React, { forwardRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useFeedback } from '../../hooks';


const Alert = forwardRef((props, ref) => {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

Alert.displayName = 'Alert';

export const SnackBarNotice = (props) => {
  const { message, open, type, hideDuration = 10000, position = { vertical: 'top', horizontal: 'center' } } = props;
  const { closeFeedback } = useFeedback();

  return (
    <Snackbar
      open={open}
      autoHideDuration={hideDuration}
      anchorOrigin={position}
      onClose={closeFeedback}>
      <Alert onClose={closeFeedback} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

