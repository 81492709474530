// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

// Hooks
import { useAuth } from '../../hooks';
import ShowAlert from './ShowAlert';
// Config
import endpoint from '../../config/endpoint.config';


const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");
  
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
export const PrivateRoute = ({ children: children, ...rest }) => {
    const { isAuthenticated, isLoading } = useAuth();
    let count = localStorage.getItem('count')

    if (isLoading) return 'Loading...';

    if (!isAuthenticated) {
        if(parseInt(count) === 5){
            localStorage.clear();
            sessionStorage.clear();
            deleteAllCookies();
            console.log('showalert')
            localStorage.setItem('count',0)
            return (<ShowAlert/>)
        }else{
            window.location.href = endpoint.COGNITO_LOGIN;
            return null;
        }
    }else{
        localStorage.setItem('count',0)
        return (
            <Route {...rest} render={ () =>
                  [children]
              }
            />
        );
    }
};

PrivateRoute.propTypes = { children: PropTypes.node.isRequired }

