// Core
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation} from 'react-apollo';
// Mutation
import { AUTHORIZE_CODE_MUTATION } from './mutation';

// Hooks
import { useAuth } from '../../hooks';
import { Loader} from "../../components";

export const Callback = () => {
    const params = new URLSearchParams(window.location.search);
    const history = useHistory();
    const { setAuthTokens } = useAuth();
    const input = { code: params.get('code')};
    const [authorizeCode] = useMutation(AUTHORIZE_CODE_MUTATION, {variables: { input }});

    useEffect(() => {
        const tokenResponse = authorizeCode();
        tokenResponse.then((response) => {             
            setAuthTokens({
                access_token: response.data.token.TokenResponse.access_token,
                id_token: response.data.token.TokenResponse.id_token,
                refresh_token: response.data.token.TokenResponse.refresh_token,
                token_type: response.data.token.TokenResponse.refresh_token,
            });
            let count = localStorage.getItem('count')
            localStorage.setItem('count', parseInt(count)+1);
            history.push('/');

        }).catch(() => {
            let count = localStorage.getItem('count')
            localStorage.setItem('count', parseInt(count)+1);
            history.push('/');
        });

    }, []);

    return <Loader />
}
