// Core
import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useQuery, useLazyQuery } from 'react-apollo';
import { Loader, TabsTable } from '../../components';
import { useStyles } from './styles';
import {
  GET_FULLFILMENT_ORDERS, GET_HISTORICAL_FULLFILMENT_ORDERS
} from './query';

const TABS = [
  {
    title: 'All Orders',
    description: '',
    filterStatus: 'all',
    filterType: 'all'
  },
  {
    title: 'DELIVERY',
    description: ': Waiting to Fulfill',
    filterStatus: 'Waiting to Fulfill',
    filterType: 'Delivery'
  },
  {
    title: 'DELIVERY',
    description: ': Out for Delivery',
    filterStatus: 'In-Transit',
    filterType: 'Delivery'
  },
  {
    title: 'CURBSIDE',
    description: ': Waiting to Fulfill',
    filterStatus: 'Waiting to Fulfill',
    filterType: 'Curbside'
  },
  {
    title: 'CURBSIDE',
    description: ': Ready for Pickup',
    filterStatus: 'Processed',
    filterType: 'Curbside'
  },
  {
    title: 'SEARCH ALL HISTORICAL ORDERS',
    description: '',
    filterStatus: 'all',
    filterType: 'all'
  }
];

export const Dashboard = ({currentTab, setCurrentTab, searchValue, setSearchValue, response, setResponse, setResponseHistorical}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const functions = {
    onCompleted: (res) => {
      setLoading(false)
      if (currentTab === 5) {
        if (res.fulfillmentOrders) { return }
        setResponseHistorical(res)
      } else {
        setResponse(res)
      }
    },
    onError: () => {
      setError(true)
    },
    fetchPolicy: 'network-only'
  }

  useQuery(GET_FULLFILMENT_ORDERS, functions);

  const [getHistorical] = useLazyQuery(GET_HISTORICAL_FULLFILMENT_ORDERS, functions);
  const [getOrders] = useLazyQuery(GET_FULLFILMENT_ORDERS, functions);

  const doSearch = () => {
    setLoading(true)
    getHistorical({ variables: { searchParam: searchValue }});
  }
  
  useEffect(()=>{
    let intervalId = setInterval(() => {
      getRefresh();
    }, (1000 * 60) * 2);

    return(() => { clearInterval(intervalId) })
  })

  const refreshPage = () =>  {
    getRefresh();
  }

  const getRefresh = () => {
    setLoading(true)
    if (searchValue) {
      getHistorical({ variables: { searchParam: searchValue }});
    } else {
      getOrders();
    }
  }

  const renderContent = useCallback(() => {
    if (loading) {
      return <Loader />;
    }
    return (
      <TabsTable refreshPage={refreshPage} data={response} tabs={TABS} doSearch={doSearch} currentTab={currentTab} setCurrentTab={setCurrentTab} searchValue={searchValue} setSearchValue={setSearchValue} />
    );
  }, [loading, response, currentTab, searchValue]);

  return (
    !error && <div>
      <Grid container direction="column" justify="space-between">
        <Grid item className={classes.welcomeHeading}>
          Welcome!
        </Grid>
      </Grid>
      {renderContent()}
    </div>
  );
};
