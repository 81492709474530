import { gql } from 'apollo-boost';
import { ORDER_FRAGMENT } from '../Dashboard/query';

export const GET_ORDER_VIEW = gql`
  query orders($id: String!) {
    fulfillmentOrder(id: $id) {
      ...orderFields
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      FulfillmentOrder{
        ...orderFields
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const FINALIZE_ORDER = gql`
  mutation fulfillOrder($input: FulfillOrderInput!) {
    fulfillOrder(input: $input) {
      FulfillmentOrder{
        ...orderFields
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const PROCESS_ORDER = gql`
  mutation processOrder($input: ProcessOrderInput!) {
    processOrder(input: $input) {
      FulfillmentOrder{
        ...orderFields
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_CANCEL_REASONS = gql`
  query getCancelationReasons {
    cancelationReasons {
      active
      label
      value
    }
  }
`;
