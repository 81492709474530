import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    modalDialog: {

        background: 'white',
        width: '500px',
        textAlign:'center',
        padding:'18px 33px',
        marginTop:'130px',
        boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
        height:'40vh'
    },

    modalDialogWrapper: {
           display:'flex',
           justifyContent:'center',
           background: '#909090',
           height:'100vh'
        },
        modalDialogImg: {
              width:'60%',
              marginTop:'13px',
              marginBottom:'13px',
              padding: '25px 0px 25px 0px',
              backgroundColor: 'white'
         },
        modalDialogButton: {
            fontsize: '14px',
            fontweight: 'bold !important',
            margin: '20px 0px 10px 0px',
            height: '40px',
            width: '100%',
            color: '#fff',
            backgroundColor: '#337ab7',
            '&:hover': {
                backgroundColor: '#286090',
            },

         },
}));

export { useStyles };
