import React from "react";
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Divider } from "@material-ui/core";


class TicketPrint extends React.PureComponent {
    render() {
        let totalCount=0;
        const { classes, orderData } = this.props;
        
        let distinctProducts= {};
        const d =  orderData.FulfillmentOrderLineItem  
        var arrCategory = d.map(c => c.OrderItemSummary.Category__c)    
        let categories = [...new Set(arrCategory)];
        categories.forEach(category => {
            distinctProducts[category] = [];
        });

        d.forEach(order => {
            let category = order.OrderItemSummary.Category__c;
            distinctProducts[category].push(order);
        });

        const isDelivery = orderData.Type === 'Delivery';
        return (
            <div className={classes.printPage}>
             <div  style={{ display:"flex",justifyContent:"space-between" }}>
                 <div>
                 <div className={classes.orderNo}>
                    Order #{orderData.OrderSummary.OrderNumber}
                </div>
                <div className={classes.orderClientDataName}>
                    {orderData.FulfilledToName}
                </div>
                <div className={classes.orderNo}>
                    {new Date(orderData.CreatedDate).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric'})}
                </div>
                <div className={classes.orderDate}>
                    {orderData.FulfilledToPhone}
                </div>

                 </div>
                {isDelivery && 
                    <div className={classes.orderBox}>
                        <div className={classes.pictureBox}></div>
                            Stop Number
                    </div> }
             </div>
                <div className={classes.orderType}>
                    {orderData.Type} Order
                </div>
               {
                   isDelivery && (
                    <>
                        <div className={classes.orderClientData}>
                       {(orderData.FulfilledToStreet).split("apt")[0]} <br />
                            {(orderData.FulfilledToStreet).split("apt").length > 1 && (
                                <>
                                 apt {(orderData.FulfilledToStreet).split("apt")[1]}
                                </>
                            )} 
                        </div>
                        <div className={classes.orderClientData1}>
                            {orderData.FulfilledToCity}, {orderData.FulfilledToState} {orderData.FulfilledToPostalCode}
                        </div>
                        <div className={classes.orderClientDataNotes}>
                            Order Notes:
                        </div>
                        <div className={classes.orderNotes}>
                        {orderData.OrderSummary.orderNotes__c && `${orderData.OrderSummary.orderNotes__c}`}
                        </div>
                    </>
                   ) }
                <Divider className={classes.orderDivider} />
                <div>
                    <b style={{ fontStyle: 'Italic' }}>(Category)</b>
                </div>
                <div>
                    <table className={classes.orderTable}>
                        <thead>
                            <tr style={{fontSize: '16px'}}>
                                <th  style={{width:"20%"}}>SKU</th>
                                <th style={{width:"70%"}} >Description</th>
                                <th style={{width:"30%", textAlign:"right" }}>QTY</th>
                            </tr>
                        </thead>
                 
                        {Object.keys(distinctProducts).map((cat) => {
                            const categoryProducts = distinctProducts[cat];
                                return (
                                    categoryProducts.map((row, index) => {
                                        totalCount = totalCount + row.Quantity;
                                        return (
                                            <>
                                            <tbody style={{ border:"1px solid red"}} key={index}>
                                               {
                                                   index === 0  &&  
                                                    <tr>
                                                        <td colSpan="2" style={{ paddingBottom:"6px",paddingTop:"6px" }}  className={classes.category}>{row.OrderItemSummary.Category__c}</td>
                                                    </tr>
                                               }
                                                <tr style={{fontSize: '16px'}}>
                                                    <td rowSpan="3"  style={{ verticalAlign:"top" }}>{row.OrderItemSummary.ProductCode}</td>
                                                    <td>{row.Description} </td>
                                                    <td  style={{ verticalAlign:"top",textAlign:"right" }}><strong>{row.Quantity}</strong></td>
                                                </tr>
                                                <tr style={{fontSize: '16px'}}>
                                                    <strong>Price:</strong>  {row.UnitPrice}
                                                </tr>
                                                <tr style={{fontSize: '16px'}}>
                                                    <td colSpan="2">
                                                    <table style={{width:"100%"}} >
                                                    <tr>
                                                    <td  style={{width:"50%"}} ><strong  style={{marginLeft:"-3.40%"}}  >UPC: </strong>  {row.OrderItemSummary.UPC__c}</td>
                                                    <td  style={{width:"50%",textAlign:"right"}}>QTY Picked_____</td>
                                                </tr>
                                                    </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                </>
                                        )})
                            )})}
                        </table>
                        <Divider className={classes.orderDivider} />
                            <div  className={classes.frozenItems}>
                                <div>Frozen items?  &nbsp;  Y / N</div>
                                <div>Total Items ordered: <strong>{totalCount}</strong></div>
                            </div>
                            <div  className={classes.frozenItems}>
                                <div># of MB Bags________</div>
                                <div># of Food Bags________</div>

                            </div>
                            <div  className={classes.frozenItems}>
                                <div># of Cases/Litter________</div>
                                <div>Beds, Trees, Etc________</div>
                            </div>
                            <Divider className={classes.orderDivider} />                        
                            <div  className={classes.orderPickedBy}>
                                <div>Order picked by: ________________________</div>
                            </div>
                        </div>
                    </div>
 );}}

export default withStyles(styles)(TicketPrint);