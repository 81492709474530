import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles(() => ({
    root:{
        margin: '1rem 0 1rem 0',
    },
    orderNumber: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings
    },
    statusLabel: {
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: '1.313rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings
    }
}));

export { useStyles };
