import moment from 'moment';

const getParsedPropertyValue = (orderBy, value) => {
    switch (orderBy) {
        case 'placedOn':
            return new Date(moment(value).format('MMMM D, YYYY h:mm A'));
        default:
            return typeof value !== 'undefined' ? value.toLowerCase(): value;
    }
};

const descendingComparator = (a, b, orderBy) => {
    if (getParsedPropertyValue(orderBy, b[orderBy]) < getParsedPropertyValue(orderBy, a[orderBy])) {
        return -1;
    }
    
    if (getParsedPropertyValue(orderBy, b[orderBy]) > getParsedPropertyValue(orderBy, a[orderBy])) {
        return 1;
    }

    return 0;
}

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {    
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    
    return stabilizedThis.map((el) => el[0]);
}

export const mapRowsData = (data) => {
    return data.map(fo => {
        let products = fo.FulfillmentOrderLineItem.map(a => a.OrderItemSummary.ProductCode);
        return {
            id: fo.Id,
            ordId: fo.OrderSummary.OrderNumber,
            name: fo.Contact.Name,
            orderType: fo.Type,
            orderStatus: fo.Status,
            placedOn: moment(fo.CreatedDate).format('MMMM D, YYYY h:mm A'),
            sku : products
        }
    });
}
