import React from 'react';
import PropTypes from 'prop-types';
import {Header, Footer} from '../components'
import { Box, Container} from '@material-ui/core';

export const MainLayout = (props) => {
    const { children } = props;
    return (
        <main>
            <Header />
            <Box mt={19} mb={10}>
                <Container  maxWidth="lg" >
                    {children}
                </Container>
            </Box>
            <Footer />
        </main>
    )
};

MainLayout.propTypes = { children: PropTypes.node.isRequired }

