import { gql } from 'apollo-boost';

export const GET_FULLFILMENT_ORDERS = gql`
    query fulfillmentOrders {
        fulfillmentOrders {
                Id
                Type
                Status
                CreatedDate
                Contact {
                    Id
                    Name
                },
                OrderSummary {
                    OriginalOrder {
                        OrderNumber
                    }
                    OrderNumber
                },
                FulfillmentOrderLineItem {
                    OrderItemSummary {
                        ProductCode
                    }
                }
      }
    }
`;

export const GET_HISTORICAL_FULLFILMENT_ORDERS = gql`
    query fulfillmentOrdersHistorical {
        fulfillmentOrdersHistorical {
                Id
                Type
                Status
                CreatedDate
                Contact {
                    Id
                    Name
                },
                OrderSummary {
                    OriginalOrder {
                        OrderNumber
                    }
                    OrderNumber
                },
                FulfillmentOrderLineItem {
                    OrderItemSummary {
                        ProductCode
                    }
                }
      }
    }
`;

export const ORDER_FRAGMENT = gql`
  fragment orderFields on FulfillmentOrder {
    Id
    OrderNumber
    Type
    Status
    CreatedDate
    TotalAmount
    TotalTaxAmount
    TotalProductAmtWithTax
    TotalAdjustmentAmount
    GrandTotalAmount
    FulfilledToPhone
    WarehouseToPhone
    FulfilledToEmailAddress
    FulfilledToName
    FulfilledToStreet
    FulfilledToCity
    FulfilledToState
    FulfilledToCountry
    FulfilledToPostalCode
    ItemCount
    FulfilledFromLocation
    Processed_By__c
    Marked_As_Picked_Up_By__c
    Marked_As_Picked_Up_By_Date__c
    Processed_By_Date__c
    Cancelled_By__c
    Cancelled_By_Date__c
    OrderSummary {
        TotalAdjDistAmount
        TotalAdjDistTaxAmount
        TotalAdjDistAmountWithTax
        TotalAdjDeliveryAmtWithTax
        TotalAdjustedDeliveryAmount
        TotalProductAdjDistAmount
        TotalProductAdjDistTaxAmount
        TotalProductAdjDistAmtWithTax
        TotalDeliveryAdjDistAmount
        TotalDeliveryAdjDistTaxAmount
        TotalDeliveryAdjDistAmtWithTax
        orderNotes__c
        OriginalOrder {
            OrderNumber
        }
        OrderNumber
    },
    Contact {
        Id
        LastName
        Phone
        Email
        Name
    }
    FulfillmentOrderLineItem {
        Id
        Description
        Quantity
        UnitPrice
        TotalAdjustmentAmount
        GrossUnitPrice
        TotalAmount
        TotalLineTaxAmount
        OrderItemSummary {
            ProductCode
            Category__c 
            UPC__c
            TotalLineAdjustmentAmtWithTax   
            OrderDeliveryGroupSummary {
                Id
                notes__c
            }
        }
        TotalLineAmount
        TotalLineTaxAmount
    }
  }
`;
