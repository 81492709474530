// Core
import ApolloClient, { InMemoryCache } from 'apollo-boost';

// Config
import { apolloResolver } from './resolvers';
import endpoint from '../config/endpoint.config';

// import { browserHistory } from '../utils';

const cache = new InMemoryCache();

const defaults = {
  palletteColor: {
    value:'Dark',
    __typename: 'PalletteColor'
  }
};

const resolvers = {
  Mutation: {
    ...apolloResolver
  }
};

const apolloClient = new ApolloClient({
  cache: cache,
  credentials: 'include',
  uri: endpoint.APOLLO_SERVER,
  clientState: {
    defaults,
    resolvers,
  },
  request: async (operation) => {
    const tokens = localStorage.getItem('tokens');
    const token = tokens ? JSON.parse(tokens).access_token : '';

    operation.setContext({
      headers: {
        authorization: token,
      },
    });
  },
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            localStorage.clear();
            window.location.replace('/');
            break
          case 'UNAUTHORIZED':
            window.location.replace('/unauthorized');
            break;
        }
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    forward(operation);
  }
});

export default apolloClient;
