import { createContext } from 'react';

const initialState = {
  feedback: {
    display: false,
    message: '',
    type: 'success'
  }
}

const context = createContext(initialState)
export const FeedbackContext = { context, initialState }
