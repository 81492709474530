import React, {useCallback} from 'react';
import { sumBy } from 'lodash';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './styles';

export const OrderCustomerDeliveryInfo = (props) => {
    const classes = useStyles();

    const {
        fulfillmentOrder: {
            DeliveryNotes,
            FulfilledToPhone,
            FulfilledToPostalCode,
            FulfilledToCountry,
            FulfilledToState,
            FulfilledToCity,
            FulfilledToStreet,
            FulfilledToName,
            GrandTotalAmount,
            TotalTaxAmount,
            TotalAmount,
            CreatedDate,
            Type,
            TotalAdjustmentAmount,
            OrderSummary: {TotalAdjustedDeliveryAmount},
            FulfillmentOrderLineItem,
            Contact : { Name, Email}
        },
        totalItemsCount
    } = props

    const getTotal = useCallback(
        (taxes = 0) => {
          let th = sumBy(FulfillmentOrderLineItem, "TotalLineAmount") + taxes;
          let res = parseFloat(th) + parseFloat(TotalAdjustmentAmount) + TotalAdjustedDeliveryAmount;
          return res.toFixed(2);
        },
        [FulfillmentOrderLineItem]
      );

    return (
        <Grid container item direction="row" className={classes.root}>
            <Grid item container direction="column" className={classes.infoContainer} md={4} sm={6} xs={12}>
                <Grid item className={classes.infoHeading}>
                    Customer Information: 
                </Grid>
                {(Name) && (
                    <Grid item className={classes.infoText}>
                        {`${Name}`}
                    </Grid>
                )
                }
                {FulfilledToPhone && (
                    <Grid item className={classes.infoText}>
                        {FulfilledToPhone}
                    </Grid>
                )
                }
                {Email && (
                    <Grid item className={`${classes.infoText} ${classes.mB}`}>
                        {Email}
                    </Grid>
                )
                }
                {CreatedDate && (
                    <Grid item className={classes.infoText}>
                        <strong>Placed:</strong> {moment(CreatedDate).format('MMMM D, YYYY h:mm A')}
                    </Grid>
                )
                }
            </Grid>
            <Grid item container direction="column" className={classes.infoContainer} md={4} sm={6} xs={12}>
                <Grid item className={classes.infoHeading}>
                    Order Information:
                </Grid>
                {TotalAmount && (
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Subtotal ({totalItemsCount} item(s)):
                        </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                        ${sumBy(FulfillmentOrderLineItem, "TotalLineAmount").toFixed(2)}                      
                        </Grid>
                    </Grid>
                )
                }
                {TotalTaxAmount.toString() && (
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Estimated Tax:
                        </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                            ${TotalTaxAmount}
                        </Grid>
                    </Grid>
                )
                }
                {(TotalAdjustmentAmount) < 0 &&
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Order Discount:
                            </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                        {`-$${(Math.abs(TotalAdjustmentAmount)).toFixed(2)}`}
                        </Grid>
                    </Grid>
                }
 
                {(TotalAdjustedDeliveryAmount) > 0 && (
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Shipping Total:
                        </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                            ${TotalAdjustedDeliveryAmount.toFixed(2)}
                        </Grid>
                    </Grid>
                )
                }

                {GrandTotalAmount && (
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoHeading}>
                            Total:
                        </Grid>
                        <Grid item className={`${classes.infoHeading} ${classes.pR}`}>
                            ${getTotal(TotalTaxAmount)}
                        </Grid>
                    </Grid>
                )
                }
            </Grid>
            {
                Type !== 'Curbside' && (
                    <Grid item container direction="column" className={classes.infoContainer} md={4} sm={6} xs={12}>
                        <Grid item className={classes.infoHeading}>
                            Delivery Information:
                        </Grid>
                        {FulfilledToName && (
                            <Grid item className={classes.infoText}>
                                {FulfilledToName}
                            </Grid>
                        )
                        }
                        {(FulfilledToStreet || FulfilledToCity || FulfilledToState || FulfilledToCountry || FulfilledToPostalCode) && (
                            <Grid item className={classes.infoText}>
                                {`${FulfilledToStreet}, ${FulfilledToCity}, ${FulfilledToState}, ${FulfilledToCountry}, ${FulfilledToPostalCode}`}
                            </Grid>
                        )
                        }
                        {FulfilledToPhone && (
                            <Grid item className={`${classes.infoText} ${classes.mB}`}>
                                {FulfilledToPhone}
                            </Grid>
                        )
                        }
                        {DeliveryNotes && (
                            <Grid item className={classes.infoText}>
                                <strong>Notes:</strong> {DeliveryNotes}
                            </Grid>
                        )
                        }
                    </Grid>
                )
            }
        </Grid>
    )
}
