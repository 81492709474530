import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';

export const OrderStatus = (props) => {
    const classes = useStyles()
    const { fulfillmentOrder } = props
    return (
        <Grid container item xs={12} direction="column" spacing={2} className={classes.root}>
            {fulfillmentOrder && fulfillmentOrder.OrderSummary && fulfillmentOrder.OrderSummary && fulfillmentOrder.OrderSummary.OrderNumber &&
            <Grid item className={classes.orderNumber}>
                Order Number: {fulfillmentOrder.OrderSummary.OrderNumber}
            </Grid>
            }
            {fulfillmentOrder && fulfillmentOrder.Status &&
            <Grid item className={classes.statusLabel}>
                Status: {fulfillmentOrder.Status}
            </Grid>
            }
        </Grid>
    );
}

