import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles(() => ({
    root:{
        margin: '1rem 0 1rem 0',
    },
    infoHeading: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.016rem',
        color: COLORS.headings,
        marginBottom: '1rem'
    },
    infoContainer:{
        padding: '2.5rem 0rem 0.5rem 0rem !important'
    },
    infoText: {
        fontWeight: 300,
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings,
        marginBottom: '1rem'
    },
    mB:{
        marginBottom: '2rem'
    },
    pR: {
        paddingRight: '6rem'
    }
}));

export { useStyles };
