import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    welcomeHeading: {
        fontWeight: 'bold',
        fontSize: '1.5rem !important',
        lineHeight: '1.75rem !important',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em !important',
        marginBottom: '2.5rem'
    },
    welcomeHeadingMobile: {
        fontWeight: 'bold',
        fontSize: '1.3rem !important',
        lineHeight: '1.75rem !important',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em !important',
    }
}));

export { useStyles };
