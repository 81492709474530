import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 'auto',
        color: `${COLORS.text} !important`,
        fontSize: '0.875rem',
        letterSpacing: '0.04em',
        backgroundColor: '#DEE0C8',
        height: '65px'
    }
}));

export { useStyles };
