import { gql } from 'apollo-boost';

export const GET_USER_INFO = gql`
    query user {
        userInfo {
            givenName
            familyName
            storeName
        }
    }
`;
