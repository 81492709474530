import React, { useReducer } from 'react';
import { SnackBarNotice } from '../components';
import { FeedbackContext } from '../context';
import { CLOSE_FEEDBACK, SET_FEEDBACK } from '../actions';

export const FeedbackProvider = ({ children }) => {
  const { context: { Provider }, initialState } = FeedbackContext;

  const [state, dispatch] = useReducer((states, action) => {
    switch (action.type) {
      case SET_FEEDBACK:
        return { ...states, feedback: action.payload };
      case CLOSE_FEEDBACK:
        return initialState;
      default:
        throw new Error();
    }
  }, initialState);

  return (
    <Provider value={[state, dispatch]}>
      {children}
      <SnackBarNotice message={state.feedback.message} open= {state.feedback.display} type={state.feedback.type} />
    </Provider>
  );
};
