const styles = () => ({
  heading: {
    textAlign: "center",
  },

  topTitle: {
    textAlign: "center",
    fontWeight:"bold",
    fontSize:"22px"
  },
  spanTag1: {
  display:"flex",
  justifyContent:"flex-end",
  fontWeight:"bold"
  },
  spanTag2: {
    display:"flex",
    justifyContent:"flex-end",
    fontWeight:"bold"
  },
  itemText: {
    fontWeight:"bold",
  },
  printPage: {
    padding: "3mm",
    width: "90mm",
    boxShadow:"0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },
  orderNo: {
    width: "100%",
    textAlign: "right",
    paddingBottom: "0mm",
    fontSize: "7pt",
    display: "flex",
    justifyContent: "space-between",
  },
  orderDivider: {
    borderBottom:"1px solid silver",
    marginBottom :"12px",
    marginTop :"12px",
  },
  orderTag: {
   fontSize:"16px",
    margin :"3px",
  },
  spanTagWrapper: {
    width:"100%",
  },
  orderQty: {
    marginRight :"6px",
    marginTop :"4px",
    fontSize:"16px",
  },
  orderTable: {
    width: "100%",
    fontSize: "8pt",
    textAlign: "left",
  },
  orderSummary: {
    width: "100%",
    fontSize: "12pt",
  },
  orderBarCode: {
    display: "flex",
    justifyContent: "center",
  },
  alignRight: {
    display:"flex",
    justifyContent:"flex-end",
  },
  subtotal: {
    fontSize: "8pt",
  },
 orderText:{
   fontSize:"13px",
   display:"flex",
   justifyContent:"space-between",
   fontWeight:"bold"
 },

});

export default styles;
