import { gql } from "apollo-boost";

export const AUTHORIZE_CODE_MUTATION = gql`
    mutation authorizeCode ($input: TokenInput!) {
        token(input: $input) {
            TokenResponse {
                token_type
                id_token
                access_token
                refresh_token
                expires_in
            }
        }
    }
`;
