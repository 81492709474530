import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2rem',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings,
        marginBottom: '1.7rem'
    },
    question: {
        fontWeight: 'normal',
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings,
        marginBottom: '1.7rem'
    },
    red: {
        color: 'red'
    },
    pin: {
        fontWeight: 300,
        fontSize: '1.1rem',
        lineHeight: '1.688rem',
        letterSpacing: '0.04rem',
        color: COLORS.headings,
        marginBottom: '1.7rem'
    },
    pinField: {
        marginBottom: '1.7rem'
    },
    pickupButton: {
        textTransform: 'none',
        backgroundColor: COLORS.buttonsColor,
        '&:hover': {
            backgroundColor: COLORS.buttonsColor,
        },
        marginTop: '2rem',
    },
    pickupButtonMobile: {
        textTransform: 'none',
        backgroundColor: COLORS.buttonsColor,
        '&:hover': {
            backgroundColor: COLORS.buttonsColor,
        },
        marginTop: '2rem',
        width: '100%'
    },
    cancelButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'white',
        },
        border: `2px solid ${theme.palette.primary.main}`
    },
    alignRight: {
        textAlign: 'right'
    },
    reasonSelect: {
        width: '100%'
    },
    numberField: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    }
}));

export { useStyles };
