import React, {useCallback, useMemo, useState} from 'react';
import {
    Button,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import {useMutation} from 'react-apollo';
import {sumBy} from 'lodash';
import {getComparator, stableSort} from '../../../utils/DataTableUtils';
import {useFeedback} from '../../../hooks';
import {Loader, MaterialDialog} from '../../../components';
import {useStyles} from './styles';
import {GET_ORDER_VIEW} from '../../../containers/OrderView/query';
import {GET_FULLFILMENT_ORDERS} from '../../../containers/Dashboard/query';
import printIcon from '../../../assets/img/print.svg';


const headCells = [
    { id: 'Description', disablePadding: false, label: 'Product Name' },
    { id: 'ProductCode', disablePadding: false, label: 'SKU/UPC' },
    { id: 'UnitPrice', disablePadding: false, label: 'Unit Price' },
    { id: 'Quantity', disablePadding: false, label: 'Quantity' },
    { id: 'TotalPicked', disablePadding: false, label: 'Total Picked' },
    { id: 'TotalLineAmount', disablePadding: false, label: 'Totals' },
];

const EnhancedTableHead = (props) => {
    const { classes, order, orderBy } = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <span className={classes.tableHeading}>{headCell.label}</span>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export const OrderDetailsDataTable = (props) => {
    const { rows, cancelOrderQuery, processOrderQuery, ordId, status, taxAmount, reasons, changeQty, quantities, totalItemsCount,
    handleReceiptPrint } = props;
    const classes = useStyles();
    const { setFeedback } = useFeedback();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [openFinal, setOpenFinal] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);

    const [cancelOrder, { loading: cancelledLoading }] = useMutation(cancelOrderQuery,
        {
            onCompleted() {
                setOpenCancel(false)
                setFeedback({
                    display: true,
                    message: 'Order cancelled successfully.',
                    type: 'success'
                });
            },
            onError({ graphQLErrors }) {
                const error = graphQLErrors[0]?.extensions?.validationErrors?.pin;
                setOpenCancel(false)
                setFeedback({
                    display: true,
                    message: `${graphQLErrors[0].message}: ${error}`,
                    type: 'error'
                });
            },
            refetchQueries: [
                { query: GET_FULLFILMENT_ORDERS }
            ]
        });

    const [processOrder, { loading: processedLoading }] = useMutation(processOrderQuery,
        {
            onCompleted() {
                setOpenFinal(false)
                setFeedback({
                    display: true,
                    message: 'Order finalized(processed) successfully.',
                    type: 'success'
                });
                handleReceiptPrint()
            },
            onError({ graphQLErrors }) {
                const error = graphQLErrors[0]?.extensions?.validationErrors?.pin;
                setOpenFinal(false)
                setFeedback({
                    display: true,
                    message: `${graphQLErrors[0].message}: ${error}`,
                    type: 'error'
                });
            },
            refetchQueries: [
                { query: GET_FULLFILMENT_ORDERS }
            ]
        });

    const handleProcessOrder = useCallback((empPin) => {
        const items = rows.FulfillmentOrderLineItem.map((item, i) => ({ id: item.Id, quantity: quantities[i]}));

        const input = {
            id: ordId,
            pin: empPin,
            items: items
        };

        setOpenFinal(false);

        processOrder({
            variables: { input },
            update(cache, { data }) {
                cache.writeQuery({
                    query: GET_ORDER_VIEW,
                    variables: { id: ordId },
                    data: {
                        fulfillmentOrder: data.processOrder.FulfillmentOrder
                    }
                });
            }
        }).catch((e) => {
            setFeedback({
                display: true,
                message: e,
                type: 'error'
            })
        })
    }, [rows, quantities]);

    const handleCancelOrder = useCallback((empPin, reason) => {
        const input = {
            id: ordId,
            pin: empPin,
            reason,
        }

        setOpenCancel(false);

        cancelOrder({
            variables: { input },
            update(cache, { data }) {
                cache.writeQuery({
                    query: GET_ORDER_VIEW,
                    variables: { id: ordId },
                    data: {
                        fulfillmentOrder: data.cancelOrder.FulfillmentOrder
                    }
                });
            }
        }).catch((e) => {
            setFeedback({
                display: true,
                message: e,
                type: 'error'
            })
        })
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getTotal = useCallback(
        (taxes = 0) => {
          return (
            sumBy(rows.FulfillmentOrderLineItem, "TotalLineAmount") +
            taxes +
            rows.TotalAdjustmentAmount + rows.OrderSummary.TotalAdjustedDeliveryAmount
          ).toFixed(2);
        },
        [rows]
      );

    const TableBodyData = useCallback(() => {
        return (
            <TableBody>
                {rows && stableSort(rows.FulfillmentOrderLineItem, getComparator(order, orderBy))
                    .map((row, index) => {
                        return (
                            <TableRow
                                tabIndex={-1}
                                key={row.Id}
                                className={classes.rowStyles}
                            >
                                <TableCell>
                                    <span className={`${classes.dataStyles} ${classes.ordID}`}>{row.Description}</span>
                                </TableCell>
                                <TableCell><span className={classes.dataStyles}>{row.OrderItemSummary.ProductCode}</span></TableCell>
                                <TableCell><span className={classes.dataStyles}>${(row.UnitPrice).toFixed(2)}</span></TableCell>
                                <TableCell><span className={classes.dataStyles}>{row.Quantity}</span></TableCell>
                                <TableCell>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ min: 0, max: row.Quantity }}
                                        margin="dense"
                                        value={quantities && quantities[index]}
                                        onChange={e => changeQty(Number(e.target.value), index)}
                                        disabled={(status === 'Canceled' || status === 'Processed' || status === 'Fulfilled')}
                                    />
                                </TableCell>
                                <TableCell><span className={classes.dataStyles}>${(row.TotalLineAmount).toFixed(2)}</span></TableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        )
    }, [rows, quantities]);

    const finalizeBtn = useCallback(() => {
        return (
            <Button
                variant="contained"
                color="primary"
                className={classes.pickupButton}
                onClick={() => setOpenFinal(true)}
                disabled={totalItemsCount === 0}
            >
                Finalize Order
            </Button>
        )
    }, [totalItemsCount])

    const filteredReasons = useMemo(() => reasons.filter((reason) => reason.active === true), [reasons]);
    const itemCount = useMemo(() => rows.FulfillmentOrderLineItem.length, [rows]);

    return (
        <>
            <Paper elevation={0}>
                <Grid container direction="row" justify="space-between">
                    <Grid item xs={12} className={classes.itemCount}>
                        {itemCount} items in Order
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table className={classes.table} >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBodyData />
                    </Table>
                </TableContainer>
            </Paper>
            <Grid container justify="flex-end">
                <Grid item container direction="column" className={classes.infoContainer} xs={12} md={7} lg={4}>
                    <Grid item className={classes.infoHeading}>
                        Order Information:
                    </Grid>
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Subtotal ({totalItemsCount} item(s)):
                        </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                            ${ sumBy(rows.FulfillmentOrderLineItem, "TotalLineAmount").toFixed(2)}                      
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Estimated Tax:
                            </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                            ${taxAmount}
                        </Grid>
                    </Grid>
                    
                    {(rows.TotalAdjustmentAmount) < 0 &&
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Order Discount:
                            </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                        {`-$${(Math.abs(rows.TotalAdjustmentAmount)).toFixed(2)}`}
                        </Grid>
                    </Grid>}

                    {(rows.OrderSummary.TotalAdjustedDeliveryAmount) > 0 && (
                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoText}>
                            Shipping Total:
                        </Grid>
                        <Grid item className={`${classes.infoText} ${classes.pR}`}>
                            ${rows.OrderSummary.TotalAdjustedDeliveryAmount.toFixed(2)}
                        </Grid>
                    </Grid>
                )
                }

                    <Grid item container justify="space-between">
                        <Grid item className={classes.infoHeading}>
                            Total:
                            </Grid>
                        <Grid item className={`${classes.infoHeading} ${classes.pR}`}>
                            ${getTotal(taxAmount)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(status === 'Processed' && 
              <>
                <Divider />
                <Grid container justify="space-between">
                    <Grid item sm={6} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={`${classes.pickupButton} ${classes.cancelButton}`}
                            onClick={() => setOpenCancel(true)}
                        >
                            Order Not Picked Up
                    </Button>
                    <MaterialDialog
                            open={openCancel}
                            handleCloseDialoge={() => setOpenCancel(false)}
                            dialogType={'cancel'}
                            cancelOrder={handleCancelOrder}
                            reasons={filteredReasons}
                        />
                    </Grid>
                </Grid>
              </>
            )}
            {(status !== 'Canceled' && status !== 'Processed' && status !== 'Fulfilled') && <>
                <Divider />
                <Grid container justify="space-between">
                    <Grid item sm={6} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={`${classes.pickupButton} ${classes.cancelButton}`}
                            onClick={() => setOpenCancel(true)}
                        >
                            Cancel Order
                    </Button>
                        <MaterialDialog
                            open={openCancel}
                            handleCloseDialoge={() => setOpenCancel(false)}
                            dialogType={'cancel'}
                            cancelOrder={handleCancelOrder}
                            reasons={filteredReasons}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Grid item className={classes.alignRight}>
                            {finalizeBtn()}
                            <MaterialDialog
                                open={openFinal}
                                handleCloseDialoge={() => setOpenFinal(false)}
                                dialogType={'final'}
                                processOrder={handleProcessOrder}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>}

            {(status === 'Processed' || status === 'Fulfilled') && <>
            <Divider />
                <Grid item style={{ display:"flex",justifyContent:"end" }}>
                    <Button color="primary" className={classes.printTicketLabel} onClick={handleReceiptPrint}>
                        <img src={printIcon} alt="print-icon" />Reprint Receipt
                    </Button>
                </Grid>
            </>
            }
            
            {(status === 'Processed' && rows.Processed_By__c) &&
            <Grid>
                <span>
                <p>Processed by  {rows.Processed_By__c} on { new Date(rows.Processed_By_Date__c).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}).replace(",","")}</p>
                </span>
            </Grid>}

            {(status === 'Fulfilled' && rows.Marked_As_Picked_Up_By__c) &&
            <Grid>
                <span>
                <p>Processed by  {rows.Processed_By__c} on { new Date(rows.Processed_By_Date__c).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}).replace(",","")}</p>
                <p>Marked as Picked up by  {rows.Marked_As_Picked_Up_By__c} on { new Date(rows.Marked_As_Picked_Up_By_Date__c).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}).replace(",","")}</p>
                </span>
            </Grid>}

            {(status === 'Canceled' && rows.Cancelled_By__c) &&
            <Grid>
                <span>
                <p>Cancelled by  {rows.Cancelled_By__c} on { new Date(rows.Cancelled_By_Date__c).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}).replace(",","")}</p>
                </span>
            </Grid>}
            {(cancelledLoading || processedLoading) && <Loader />}
        </>
    );
}
